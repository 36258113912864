import React from 'react';
import '../App.css';

const Main = () => {

  return (
    <>
      <header className="english-header">
        <div className="header-left">
          <div className="header-logo">
            <a href="https://www.hayashi-seimen.com/" target="_blank"><img src="../images/logo.png" alt="Hayashi Seimen Europe"></img></a>
          </div>
          <p>Hayashi Seimen Europe</p>
        </div>
        <div className="header-right">
          <div className="header-button conversion-button">
            <a href="/contact">Contact us</a>
          </div>
          <div className="header-language text-button">
            <a href="/jp">JP</a>
          </div>
        </div>
      </header>
      <main className="english-main">
        <section className="main">
          <div className="main-text">
            <p className="main-text__lead">Ready in <span>3</span> minutes only!</p>
            <h1>Ramen noodle<br /><span>with your restaurant</span></h1>
            <div className="main-text__conversion">
              <p className="main-conversion__lead">Get free samples of noodles</p>
              <div className="main-conversion__button conversion-button">
                <a href="/contact">Contact us</a>
              </div>
            </div>
          </div>
          <div className="main-img">
            <img src="../images/ramen_top.png" alt=""></img>
          </div>
        </section>
        <section className="introduction contents back-black">
          <div className="introduction-text">
            <h2>Experience high-quality ramen made easier</h2>
            <p>Established in 1947 in Japan, Hayashi Seimen is a long-established noodle manufacturer. <br /> Our products are now available in over 800 locations across Japan, <br className="pc-only" />providing a simple solution to creating high-quality ramen for anyone.</p>
          </div>
        </section>
        <section className="feature contents back-yellow">
          <div className="feature-text">
            <h2>Our Key Features</h2>
            <div className="feature-box flex">
              <div className="feature-box__item">
                <div className="feature-item__img">
                  <img src="../images/feature01.jpg" alt=""></img>
                </div>
                <div className="feature-item__text">
                  <h3>Simple to add to your menu!</h3>
                  <p>We deliver noodles and soup as a set, making it easy to add to your menu.</p>
                </div>
              </div>
              <div className="feature-box__item">
                <div className="feature-item__img">
                  <img src="../images/feature02.jpg" alt=""></img>
                </div>
                <div className="feature-item__text">
                  <h3>Cooking instructions included!</h3>
                  <p>A free cooking manual is included, so even first-time users can confidently add it to their restaurant menu.</p>
                </div>
              </div>
              <div className="feature-box__item">
                <div className="feature-item__img">
                  <img src="../images/feature03.jpg" alt=""></img>
                </div>
                <div className="feature-item__text">
                  <h3>Simple cooking</h3>
                  <p>Cooking is finished in just 4 easy steps.</p>
                  <div className="text-button"><a href="#cook">Ask more details</a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="taste contents">
          <h2>3 tasty options to pick from!</h2>
          <div className="taste-box">
            <div className="taste-box__item">
              <div className="taste-item__text">
                <h3>Soy Sauce Ramen</h3>
                <p>Classic Japanese noodle soup characterized by its soy sauce-based broth.<br />Shoyu ramen is one of the most traditional styles of ramen in Japan, making it a favorite among ramen enthusiasts.</p>
              </div>
              <div className="taste-item__img">
                <img src="../images/ramen_shoyu.png" alt=""></img>
              </div>
            </div>
            <div className="taste-box__item taste-item__tonkothu">
              <div className="taste-item__img">
                <img src="../images/ramen_tonkothu.png" alt=""></img>
              </div>
              <div className="taste-item__text">
                <h3>Tonkotsu Ramen</h3>
                <p>Creamy and boasting a rich, savory flavor that is deeply satisfying, this is the perfect dish for those seeking a fulfilling and flavorful meal.</p>
              </div>
            </div>
            <div className="taste-box__item">
              <div className="taste-item__text">
                <h3>Miso Ramen</h3>
                <p>Rich, savory flavor that balances sweetness and saltiness.<br />Miso ramen is a satisfying and flavorful dish, perfect for anyone seeking a warming and hearty meal.</p>
              </div>
              <div className="taste-item__img">
                <img src="../images/ramen_miso.png" alt=""></img>
              </div>
            </div>
          </div>
          <div className="taste-conversion conversion">
            <p>Get free sample noodles!</p>
            <div className="taste-conversion__button conversion-button">
              <a href="/contact">Contact us</a>
            </div>
          </div>
        </section>
        <section className="cook contents back-yellow" id="cook">
          <h2>4 Easy Steps to Prepare Ramen</h2>
          <div className="cook-box flex">
            <div className="cook-box__item">
              <div className="cook-item__step">
                <p className="cook-step__text">STEP</p>
                <p className="cook-step__number">01</p>
              </div>
              <div className="cook-item__img">
                <img src="../images/cook01.jpg" alt=""></img>
              </div>
              <div className="cook-item__text">
                <div className="cook-text__explan">
                  <p>Add frozen noodles to boiling water, stirring to separate for 2 minutes.</p>
                </div>
                <div className="cook-text__point">
                  <p className="cook-text__pointTitle">POINT</p>
                  <p>Loosen the noodles before placing them in the water.</p>
                </div>
              </div>
            </div>
            <div className="cook-box__item">
              <div className="cook-item__step">
                <p className="cook-step__text">STEP</p>
                <p className="cook-step__number">02</p>
              </div>
              <div className="cook-item__img">
                <img src="../images/cook02.jpg" alt=""></img>
              </div>
              <div className="cook-item__text">
                <div className="cook-text__explan">
                  <p>Prepare the soup in bowls while the noodles cook.</p>
                </div>
                <div className="cook-text__point">
                  <p className="cook-text__pointTitle">POINT</p>
                  <p>Preheat the bowl in advance.</p>
                </div>
              </div>
            </div>
            <div className="cook-box__item">
              <div className="cook-item__step">
                <p className="cook-step__text">STEP</p>
                <p className="cook-step__number">03</p>
              </div>
              <div className="cook-item__img">
                <img src="../images/cook03.jpg" alt=""></img>
              </div>
              <div className="cook-item__text">
                <div className="cook-text__explan">
                  <p>Drain the noodles in a colander and let the water drain well.</p>
                </div>
                <div className="cook-text__point">
                  <p className="cook-text__pointTitle">POINT</p>
                  <p>Make sure to drain the water thoroughly.</p>
                </div>
              </div>
            </div>
            <div className="cook-box__item">
              <div className="cook-item__step">
                <p className="cook-step__text">STEP</p>
                <p className="cook-step__number">04</p>
              </div>
              <div className="cook-item__img">
                <img src="../images/cook04.jpg" alt=""></img>
              </div>
              <div className="cook-item__text">
                <div className="cook-text__explan">
                  <p>Place the noodles into the bowl with the soup and add the toppings.</p>
                </div>
                <div className="cook-text__point">
                  <p className="cook-text__pointTitle">POINT</p>
                  <p>Add the noodles after the soup.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cook-movie">
            <video src="../move/Hayashi_lp.mp4" controls></video>
          </div>
        </section>
        <section className="about contents back-black">
          <h2>About Us</h2>
          <div className="about-box flex">
            <div className="about-item__text">
              <p>Established in 1947 in Japan, Hayashi Seimen is a long-established noodle manufacturer. Our products are now available at over 800 locations across Japan, providing a simple solution for anyone to create high-quality ramen.</p>
            </div>
            <div className="about-item__img">
              <img src="../images/feature01.jpg" alt=""></img>
            </div>
          </div>
        </section>
        <section className="trustAchievements contents back-blackFoto">
          <h2>Trusted expertise as an industry pioneer</h2>
          <p>Our company prioritizes the safety and peace of mind of our customers while ensuring the consistent quality and deliciousness of our noodles. We have established unique checkpoints, using both measuring instruments and visual inspections, at each stage of production in our noodle factory, enforcing strict quality and hygiene controls.<br />For example, during the critical mixing process, we make slight adjustments to the water content, temperature, and mixing time to align with the seasonal conditions of the wheat flour, resulting in more stable and high-quality noodles.</p>
        </section>
        <section className="production contents back-yellow">
          <h2>Stable Production System</h2>
          <p>We have established a system that can consistently produce tens of thousands of servings of noodles each day.</p>
          <div className="production-lead"><p>Commitment to quality</p></div>
          <ul className="production-box">
            <li>
              <h3>Storage</h3>
              <div className="production-text">
                <h4>Wheat flour</h4>
                <p>We manage our wheat flour in storage facilities that maintain optimal temperature and humidity levels year-round. To protect against pests, we regularly conduct sterilization and inspections, ensuring the flour remains in a stable condition for consistent use.</p>
              </div>
            </li>
            <li>
              <h3>Manufacturing</h3>
              <div className="production-text">
                <ol className="production-step">
                  <li>
                    <div className="production-step__title">
                      <p className="production-step__number">STEP01</p>
                      <h4>Mixing</h4>
                    </div>
                    <p>The process of mixing wheat flour and water in a mixer is called 'mixing.' We combine ingredients such as wheat flour, starch, egg whites, and calcium with Kansui (alkaline water) and other additives to create a kneading water mixture. At our company, we adjust the mixing speed in two stages: first, we use high-speed rotation to quickly blend the wheat flour and water, followed by low-speed rotation to knead the dough and promote gluten development.</p>
                  </li>
                  <li>
                    <div className="production-step__title">
                      <p className="production-step__number">STEP02</p>
                      <h4>Combination</h4>
                    </div>
                    <p>The combination process involves gathering the granulated dough from mixing and forming it into a sheet. Since the freshly mixed wheat flour has a rough, uneven texture, this process smooths the surface. It also promotes gluten bonding, increasing the dough's density.</p>
                  </li>
                  <li>
                    <div className="production-step__title">
                      <p className="production-step__number">STEP03</p>
                      <h4>Rolling</h4>
                    </div>
                    <p>The thickness of the noodles varies depending on the dough, so we pass it through two rollers to adjust it. If the dough is rolled out all at once, the gluten may break down, diminishing the flavor of the noodles. To prevent this, we use four rollers, gradually rolling the dough to stretch the gluten and smooth the surface.</p>
                  </li>
                  <li>
                    <div className="production-step__title">
                      <p className="production-step__number">STEP04</p>
                      <h4>Cutting</h4>
                    </div>
                    <p>This process involves feeding the dough, which has been smoothed through the combination and rolling steps, into a machine to create noodles. Our company uses cutting blades ranging from #4 to #30, enabling us to manufacture noodles tailored to our customers' needs.</p>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <h3>Packing</h3>
              <div className="production-text">
                <h4>Packing</h4>
                <p>The cut noodles are carefully packaged as they move along a conveyor belt. To prevent contamination by foreign objects, they pass through metal detectors, ensuring that only safe and secure noodles reach our customers.</p>
                <p className="note">Note: Since noodles are a perishable product, please store them in the refrigerator (below 10°C) to maintain their flavor and texture.</p>
              </div>
            </li>
          </ul>
        </section>
        <section className="international contents">
          <h2>Our mission</h2>
          <div className="international-box flex">
            <div className="international-item__text">
              <h3>Bringing authentic Japanese Ramen to the world</h3>
              <p>We will offer Hayashi Seimen's ramen primarily in Germany, allowing you to enjoy Japanese ramen abroad. With our included cooking instructions, you can easily add it to your menu at overseas locations, providing the authentic taste of Japanese ramen.</p>
            </div>
            <div className="international-item__img">
              <img src="../images/map.png" alt=""></img>
            </div>
          </div>
        </section>
        <section className="contact contents back-blackFoto">
          <p className="contact-lead">Bring a new vibe to your restaurant. <br />Why not easily add an authentic ramen menu that will delight your customers?</p>
          <div className="contact-conversion conversion">
            <p>Get free samples of noodles </p>
            <div className="contact-conversion__button conversion-button">
              <a href="/contact">Contact us</a>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className="footer-box">
          <div className="footer-box__logo">
            <a href="https://www.hayashi-seimen.com/" target="_blak"><img src="../images/logo.png" alt="Hayashi Seimen Europe"></img></a>
          </div>
          <div className="footer-box__adress">
            <address>
              <p>Hayashi Seimen Europe</p>
              <dl><dt>Adress :</dt><dd>Prinzenalle 7, 40549 Düsseldorf Germany</dd></dl>
              <dl><dt>Mail :</dt><dd><div class="text-button"><a href="mailto:contact@hayashi-seimen.eu">contact@hayashi-seimen.eu</a></div></dd></dl>
            </address>
          </div>
        </div>
        <div className="footer-copy">
          <small>© 2024<script type="text/javascript">myDate = new Date() ;myYear = myDate.getFullYear ();document.write(myYear);</script> Hayashi Seimen Europe</small>
        </div>
      </footer>
    </>
  );
};

export default Main