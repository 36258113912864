import React from 'react';
import '../App.css';

const Japan = () => {

  return (
    <>
      <header>
        <div className="header-logo">
          <a href="https://www.hayashi-seimen.com/" target="_blank"><img src="../images/logo.png" alt="林製麺所"></img></a>
        </div>
        <div className="header-right">
          <div className="header-button conversion-button">
            <a href="/contact">お問い合わせはこちら</a>
          </div>
          <div className="header-language text-button">
            <a href="/">EN</a>
          </div>
        </div>
      </header>
      <main>
        <section className="main">
          <div className="main-text">
            <p className="main-text__lead">最短<span>3</span>分で提供可能</p>
            <h1>伝統の味<span>を</span><br />誰でも簡単<span>に!</span></h1>
            <div className="main-text__conversion">
              <p className="main-conversion__lead">無料サンプル・導入の相談はこちら</p>
              <div className="main-conversion__button conversion-button">
                <a href="/contact">お問い合わせはこちら</a>
              </div>
            </div>
          </div>
          <div className="main-img">
            <img src="../images/ramen_top.png" alt=""></img>
          </div>
        </section>
        <section className="introduction contents back-black">
          <div className="introduction-text">
            <h2>高品質なラーメンを <br class="sp-only" /> もっと手軽に</h2>
            <p>林製麺は1947年に日本で創業した老舗製麺所です。<br /> 現在、日本全国で800店舗以上に導入されていて、品質の高い ラーメンを、<br className="pc-only" />誰でも簡単にできる仕組みを提供しています。</p>
          </div>
        </section>
        <section className="feature contents back-yellow">
          <div className="feature-text">
            <h2>私たちの特徴</h2>
            <div className="feature-box flex">
              <div className="feature-box__item">
                <div className="feature-item__img">
                  <img src="../images/feature01.jpg" alt=""></img>
                </div>
                <div className="feature-item__text">
                  <h3>手軽にメニューに追加できる！</h3>
                  <p>麺とスープをセットでお届けするので手軽にメニューに追加できます。</p>
                </div>
              </div>
              <div className="feature-box__item">
                <div className="feature-item__img">
                  <img src="../images/feature02.jpg" alt=""></img>
                </div>
                <div className="feature-item__text">
                  <h3>調理マニュアル付き！</h3>
                  <p>無料の調理マニュアルがついているので初めての方でも安心して店舗メニューに導入頂けます。</p>
                </div>
              </div>
              <div className="feature-box__item">
                <div className="feature-item__img">
                  <img src="../images/feature03.jpg" alt=""></img>
                </div>
                <div className="feature-item__text">
                  <h3>調理が簡単！</h3>
                  <p>たったの4ステップで調理が完了します。</p>
                  <div className="text-button"><a href="#cook">詳しくはこちら</a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="taste contents">
          <h2>選べる3つの味!!</h2>
          <div className="taste-box">
            <div className="taste-box__item">
              <div className="taste-item__text">
                <h3>醤油ラーメン</h3>
                <p>伝統的な日本の醤油ベースのわい。<br />豊かな香りが特徴。</p>
              </div>
              <div className="taste-item__img">
                <img src="../images/ramen_shoyu.png" alt=""></img>
              </div>
            </div>
            <div className="taste-box__item taste-item__tonkothu">
              <div className="taste-item__img">
                <img src="../images/ramen_tonkothu.png" alt=""></img>
              </div>
              <div className="taste-item__text">
                <h3>とんこつラーメン</h3>
                <p>濃厚でクリーミーな豚骨スープ。<br />深いコクが自慢。</p>
              </div>
            </div>
            <div className="taste-box__item">
              <div className="taste-item__text">
                <h3>味噌ラーメン</h3>
                <p>味噌のコクと豊かな風味が特徴。</p>
              </div>
              <div className="taste-item__img">
                <img src="../images/ramen_miso.png" alt=""></img>
              </div>
            </div>
          </div>
          <div className="taste-conversion conversion">
            <p>無料サンプル・<br className="sp-only" />導入の相談はこちら</p>
            <div className="taste-conversion__button conversion-button">
              <a href="/contact">お問い合わせはこちら</a>
            </div>
          </div>
        </section>
        <section className="cook contents back-yellow" id="cook">
          <h2>調理方法は<br className="sp-only" />たったの4ステップ！</h2>
          <div className="cook-box flex">
            <div className="cook-box__item">
              <div className="cook-item__step">
                <p className="cook-step__text">STEP</p>
                <p className="cook-step__number">01</p>
              </div>
              <div className="cook-item__img">
                <img src="../images/cook01.jpg" alt=""></img>
              </div>
              <div className="cook-item__text">
                <div className="cook-text__explan">
                  <p>沸騰したたっぷりのお湯に冷凍のまま麺を入れ、2分間ほぐしながらまぜる。</p>
                </div>
                <div className="cook-text__point">
                  <p className="cook-text__pointTitle">POINT</p>
                  <p>あらかじめ麺をほぐしておくと、さらに美味しくなります。</p>
                </div>
              </div>
            </div>
            <div className="cook-box__item">
              <div className="cook-item__step">
                <p className="cook-step__text">STEP</p>
                <p className="cook-step__number">02</p>
              </div>
              <div className="cook-item__img">
                <img src="../images/cook02.jpg" alt=""></img>
              </div>
              <div className="cook-item__text">
                <div className="cook-text__explan">
                  <p>麺を茹でている間に容器にスープを準備。</p>
                </div>
                <div className="cook-text__point">
                  <p className="cook-text__pointTitle">POINT</p>
                  <p>容器をあらかじめ温めておくとスープも冷めづらいです。</p>
                </div>
              </div>
            </div>
            <div className="cook-box__item">
              <div className="cook-item__step">
                <p className="cook-step__text">STEP</p>
                <p className="cook-step__number">03</p>
              </div>
              <div className="cook-item__img">
                <img src="../images/cook03.jpg" alt=""></img>
              </div>
              <div className="cook-item__text">
                <div className="cook-text__explan">
                  <p>麺をザルにあけて、お湯を良く切る。</p>
                </div>
                <div className="cook-text__point">
                  <p className="cook-text__pointTitle">POINT</p>
                  <p>ザルを持ち上げて、しっかりお湯を切りましょう。</p>
                </div>
              </div>
            </div>
            <div className="cook-box__item">
              <div className="cook-item__step">
                <p className="cook-step__text">STEP</p>
                <p className="cook-step__number">04</p>
              </div>
              <div className="cook-item__img">
                <img src="../images/cook04.jpg" alt=""></img>
              </div>
              <div className="cook-item__text">
                <div className="cook-text__explan">
                  <p>スープの入った容器に麺を入れ、具材を盛り付ける。</p>
                </div>
                <div className="cook-text__point">
                  <p className="cook-text__pointTitle">POINT</p>
                  <p>麺を入れてからスープを入れると味が変わってしまいます。</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cook-movie">
            <video src="../move/Hayashi_lp.mp4" controls></video>
          </div>
        </section>
        <section className="about contents back-black">
          <h2>林製麺所とは</h2>
          <div className="about-box flex">
            <div className="about-item__text">
              <p>国内の数多くの店に麺を提供する、名古屋で70年の歴史を誇る中華麺専門の製麺所です。<br />
                林製麺は1947年に日本で創業した老舗製麺所。現在日本全国で800店舗以上に導入されていて、品質の高いラーメン、誰でも簡単にできる仕組みを提供しています。</p>
            </div>
            <div className="about-item__img">
              <img src="../images/feature01.jpg" alt=""></img>
            </div>
          </div>
        </section>
        <section className="trustAchievements contents back-blackFoto">
          <h2>業界パイオニアとしての<br className="sp-only" />信頼と実績</h2>
          <p>弊社はお客様の安心・安全を第一に考え、また均質な美味しい麺つくり麺作りの為。製麺工場の作業工程ごとに計測器や目視による独自のチェック項目を設け、厳しい品質管理と衛生管理を行っています。<br />
            例えば、製麺の要となるミキシング工程においては季節ごとの小麦粉の状態に合わせ、生地への加水量・温度・ミキシング時間をわずかに変えることで、より安定した麺をご提供いたしております。</p>
        </section>
        <section className="production contents back-yellow">
          <h2>安定した生産体制</h2>
          <p>切り出し工程に置いても最新鋭の製麺機を3ライン用いて一日に何万食もの麺を安定して生産できる環境を整えています。</p>
          <div className="production-lead"><p>こだわるからこそ旨い</p></div>
          <ul className="production-box">
            <li>
              <h3>保管</h3>
              <div className="production-text">
                <h4>小麦粉の保管</h4>
                <p>小麦粉は1年を通じて、一定の温度・湿度を適正に保てるように保管庫で管理しています。また、害虫などの被害から守るために定期的な殺菌・点検を慣行。常に安定した状態の小麦粉が使用可能です。</p>
              </div>
            </li>
            <li>
              <h3>製造</h3>
              <div className="production-text">
                <ol className="production-step">
                  <li>
                    <div className="production-step__title">
                      <p className="production-step__number">STEP01</p>
                      <h4>ミキシング</h4>
                    </div>
                    <p>小麦粉と水をミキサーで混ぜあわせる作業が「ミキシング」です。材料の小麦粉・でん粉・卵白・カルシウムなどに、かん水、添加物などを入れた練水（ねりみず）を混ぜあわせます。当社では、混ぜるスピードを2段階で調整。高速回転で小麦粉と水を素早くかき混ぜてなじませ、低速回転で生地をこね、グルテンの発生を促進させます。</p>
                  </li>
                  <li>
                    <div className="production-step__title">
                      <p className="production-step__number">STEP02</p>
                      <h4>複合</h4>
                    </div>
                    <p>ミキシングで粒状になった生地を帯状にまとめる作業です。混ぜあわせたばかりの小麦粉は、デコボコした形状のため、複合によって表面をなめらかにします。複合を行うとグルテンの結合を促進し、密度を高めることが可能です。</p>
                  </li>
                  <li>
                    <div className="production-step__title">
                      <p className="production-step__number">STEP03</p>
                      <h4>圧延（あつえん）</h4>
                    </div>
                    <p>生地によって麺の厚みが異なるため、2つのローラーの間を通して厚みを整えます。その際、一気に圧延してしまうと、グルテンが壊れ麺のうまみが損なわれることも。当社では、それを防止するために4つのローラーを使用。少しずつ圧延することでグルテンを引き延ばし、表面をなめらかにします。</p>
                  </li>
                  <li>
                    <div className="production-step__title">
                      <p className="production-step__number">STEP04</p>
                      <h4>切りだし</h4>
                    </div>
                    <p>複合と圧延でなめらかにした生地を機械にかけ、麺にする作業です。<br />当社では麺の形状別に4番～30番までの切刃（きりは）を取り扱っており、お客様のニーズにあわせた麺を製造しております。</p>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <h3>包装</h3>
              <div className="production-text">
                <h4>包装</h4>
                <p>切りだしされた麺は、ベルトコンベヤーを通って丁寧に包装。その際、異物混入を防ぐために金属探知機にかけ、安心・安全な麺のみが商品としてお客様のもとへ届きます。</p>
                <p className="note">※麺は「生もの」ですので、おいしさやコシを持続するためにも、麺の保管は冷蔵庫（10℃以下）でお願いします。</p>
              </div>
            </li>
          </ul>
        </section>
        <section className="international contents">
          <h2>国内から欧州へ</h2>
          <div className="international-box flex">
            <div className="international-item__text">
              <h3>海外で手軽に日本のラーメンを提供</h3>
              <p>ドイツを中心に今後はEU全体に林製麺所のラーメンをご提供致します。日本のラーメンを海外で。海外店舗でも手軽にメニューに追加できる調理マニュアル付きなので日本のラーメンそのままの味をご提供頂けます。</p>
            </div>
            <div className="international-item__img">
              <img src="../images/map.png" alt=""></img>
            </div>
          </div>
        </section>
        <section className="contact contents back-blackFoto">
          <p className="contact-lead">あなたのお店に新しい風を。<br />お客様が喜ぶ本格ラーメンメニューを 手軽に追加してみませんか?</p>
          <div className="contact-conversion conversion">
            <p>無料サンプル・導入の相談はこちら</p>
            <div className="contact-conversion__button conversion-button">
              <a href="/contact">お問い合わせはこちら</a>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className="footer-box">
          <div className="footer-box__logo">
            <a href="https://www.hayashi-seimen.com/" target="_blak"><img src="../images/logo.png" alt="林製麺所"></img></a>
          </div>
          <div className="footer-box__adress">
            <address>
              <p>Hayashi Seimen Europe</p>
              <dl><dt>Adress :</dt><dd>Prinzenalle 7, 40549 Düsseldorf Germany</dd></dl>
              <dl><dt>Mail :</dt><dd><div class="text-button"><a href="mailto:contact@hayashi-seimen.eu">contact@hayashi-seimen.eu</a></div></dd></dl>
            </address>
          </div>
        </div>
        <div className="footer-copy">
          <small>© 2024<script type="text/javascript">myDate = new Date() ;myYear = myDate.getFullYear ();document.write(myYear);</script> 林製麺所</small>
        </div>
      </footer>
    </>
  );
};

export default Japan